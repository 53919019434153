import { PROJECTS, PROJECTS_TIPOLOGIES, PROJECT_LIFECYCLES, PROJECT_LOC } from './projects';

export const SLIDER_HOME = [
	{
		video: require('../assets/videos/video_main_cosapi.mp4'),
		headTitle: 'VIDEO',
		name: 'Cosapi',
		index: 0,
		about: 'Saber más',
	},
	{
		img: require('../assets/images/sliderMain/render-midgo.jpg'),
		imgMovil: require('../assets/images/render/render_midgo.jpg'),
		...PROJECT_LOC.midgo,
		phases: PROJECT_LIFECYCLES.get(PROJECTS.midgo),
		project: 'Midgo',
		bedroomFeePrices: [
			['2 Dorms', '2,950'],
			['3 Ambientes', '3,850'],
		],
		index: 1,
		about: 'Saber más',
	},
	{
		img: require('../assets/images/sliderMain/render-momen.jpg'),
		imgMovil: require('../assets/images/sliderMain/render-momen-movil.jpg'),
		...PROJECT_LOC.momen,
		phases: PROJECT_LIFECYCLES.get(PROJECTS.momen),
		project: 'Momen',
		bedroomFeePrices: [
			['2 Dorms', '2,200'],
			['3 Dorms', '2,600'],
		],
		index: 1,
		about: 'Saber más',
	},
	{
		img: require('../assets/images/sliderMain/ativa-desktop.png'),
		imgMovil: require('../assets/images/sliderMain/ativa-movil.png'),
		...PROJECT_LOC.ativa,
		phases: PROJECT_LIFECYCLES.get(PROJECTS.ativa),
		project: 'Ativa',
		bedroomFeePrices: [
			['2 Dorms', '4,200'],
			['3 Dorms', '5,900'],
		],
		index: 2,
		about: 'Saber más',
	},
	{
		img: require('../assets/images/sliderMain/Muvin.png'),
		imgMovil: require('../assets/images/sliderMain/Muvin_mobile.png'),
		...PROJECT_LOC.muvin,
		phases: PROJECT_LIFECYCLES.get(PROJECTS.muvin),
		project: 'Muvin',
		bedroomFeePrices: [
			['2 Dorms', '3,600'],
			['3 Dorms', '4,600'],
		],
		index: 2,
		about: 'Saber más',
	},
	// {
	//     img: require("../assets/images/sliderMain/Epiqe.png"),
	//     imgMovil: require("../assets/images/sliderMain/Epiqe_mobile.png"),
	//     ...PROJECT_LOC.épiqe,
	//     phases: PROJECT_LIFECYCLES.get(PROJECTS.épiqe),
	//     singlePhaseFallbackText: "Últimos 2 depas",
	//     project: "Épiqe",
	//     bedroomFeePrices: [
	//         ["2 Dorms", "2,800"],
	//         ["3 Dorms", "4,000"],
	//     ],
	//     index: 3,
	//     about: "Saber más"
	// },
	{
		img: require('../assets/images/sliderMain/Nesta.png'),
		imgMovil: require('../assets/images/sliderMain/Nesta_mobile.png'),
		...PROJECT_LOC.nesta,
		phases: PROJECT_LIFECYCLES.get(PROJECTS.nesta),
		project: 'Nesta',
		bedroomFeePrices: [
			['2 Dorms', '4,300'],
			['3 Dorms', '4,810'],
		],
		index: 4,
		about: 'Saber más',
	},
];
export const PROJECT_CARDS = [
	{
		project: 'Midgo',
		imgBrand: 'brand-Midgo',
		imgProject: require('../assets/images/render/render_midgo.jpg'),
		imgBrand: require('../assets/images/icons/brand-midgo.svg').default,
		allFases: [{ fase: 'Fase 2', type: 'En Preventa' }],
		fases: 'Preventa',
		fase: 'Preventa',
		frase: 'A pasos del <strong>C.C. El Polo</strong>',
		valuation: 'A pasos de San Isidro',
		...PROJECT_LOC.midgo,
		...PROJECTS_TIPOLOGIES.momen,
		dorms: ' 2 y 3',
		dormsShow: '1, 2 y 3 ambientes',
		meters: '40m² - 71m²',
		views: { desde: '233', hasta: '150' },
		tendency: 'Tendencia',
		all: 'all',
		link: 'https://midgo.cosapiinmobiliaria.com.pe/',
		index: 1,
		button: 'Registrate aquí',
		price_from: 'S/425,000',
	},
	{
		project: 'Momen',
		imgBrand: 'brand-momen',
		imgProject: require('../assets/images/render/render-momen.jpg'),
		imgBrand: require('../assets/images/icons/momen-brand.svg').default,
		allFases: [{ fase: 'Fase 2', type: 'En Preventa' }],
		fases: 'Preventa',
		fase: 'Preventa',
		frase: 'A pasos del <strong>C.C. El Polo</strong>',
		valuation: 'A 5 min. del C.C. La Rambla',
		...PROJECT_LOC.momen,
		...PROJECTS_TIPOLOGIES.momen,
		dorms: ' 2 y 3',
		dormsShow: '2 y 3 Dorms',
		meters: '40m² - 71m²',
		views: { desde: '120', hasta: '250' },
		tendency: 'Tendencia',
		all: 'all',
		link: 'https://www.momen.cosapiinmobiliaria.com.pe/',
		index: 1,
		button: 'Registrate aquí',
		price_from: 'S/281,000',
	},
	{
		project: 'Ativa',
		imgBrand: 'brand-ativa',
		imgProject: require('../assets/images/render/ativa.png'),
		imgBrand: require('../assets/images/multiproducto/ATIVA.png'),
		allFases: [
			{ fase: 'Fase 1', type: 'En Construcción' },
			{ fase: 'Fase 2', type: 'En Construcción' },
		],
		fases: 'En Construcción',
		fase: 'En Construcción',
		frase: 'A pasos del <strong>C.C. El Polo</strong>',
		valuation: 'A pasos del C.C. El Polo',
		...PROJECT_LOC.ativa,
		...PROJECTS_TIPOLOGIES.ativa,
		dorms: '1, 2 y 3',
		dormsShow: '1, 2 y 3 Dorms',
		meters: '40m² - 71m²',
		views: { desde: '453', hasta: '443' },
		tendency: 'Tendencia',
		all: 'all',
		link: 'https://ativa.cosapiinmobiliaria.com.pe/',
		index: 1,
		button: 'Registrate aquí',
		price_from: 'S/383,000',
	},

	{
		project: 'muvin',
		imgBrand: 'brand-muvin',
		imgProject: require('../assets/images/render/render-muvin.jpg'),
		imgBrand: require('../assets/images/multiproducto/MUVIN.png'),
		allFases: [{ fase: '', type: 'Entrega Inmediata' }],
		fases: 'Entrega Inmediata',
		fase: 'Entrega Inmediata',
		frase: 'A unos pasos de San Isidro',
		valuation: 'A minutos del Centro Financiero',
		...PROJECT_LOC.muvin,
		...PROJECTS_TIPOLOGIES.muvin,
		dorms: '2 y 3',
		dormsShow: '2 y 3 Dorms',
		meters: '45m² - 70m²',
		views: { desde: '123', hasta: '234' },
		tendency: 'Tendencia',
		all: 'all',
		link: 'https://muvin.cosapiinmobiliaria.com.pe/',
		index: 2,
		button: 'Registrate aquí',
		price_from: 'S/448,000',
	},

	{
		project: 'nesta',
		imgProject: require('../assets/images/render/render-nesta.jpg'),
		imgBrand: require('../assets/images/multiproducto/nesta.png'),
		allFases: [
			{ fase: 'Fase 4', type: 'Entrega Inmediata' },
			{ fase: 'Fase 5', type: 'En preventa' },
		],
		fases: 'Entrega Inmediata',
		fase: 'Entrega Inmediata',
		frase: 'Vive frente al Campo de Marte',
		valuation: 'Frente al Campo de Marte',
		...PROJECT_LOC.nesta,
		...PROJECTS_TIPOLOGIES.nesta,
		dorms: '2 y 3',
		dormsShow: '2 y 3 Dorms',
		meters: '40m² - 109m²',
		views: { desde: '715', hasta: '1524' },
		tendency: 'Tendencia',
		all: 'all',
		link: 'https://nesta.com.pe/',
		index: 4,
		button: 'Registrate aquí',
		price_from: 'S/527,500',
	},
	// {
	//     project: "épiqe",
	//     imgBrand: "brand-epiqe",
	//     imgProject: require("../assets/images/render/render-eqipe.jpg"),
	//     imgBrand: require("../assets/images/multiproducto/EPIQE.png"),
	//     allFases:[{fase:"",type:"Vendido"}],
	//     fases: "Vendido",
	//     fase: "Vendido",
	//     frase: "A unos pasos del CC. Jockey Plaza",
	//     valuation: "",
	//     ...PROJECT_LOC.épiqe,
	//     ...PROJECTS_TIPOLOGIES.épiqe,
	//     dorms: "1, 2 y 3",
	//     dormsShow: "1, 2 y 3 Dorms",
	//     meters: "40m² - 78m²",
	//     views: {desde:"270",hasta:"474"},
	//     tendency: "Tendencia",
	//     all: "all",
	//     link: "https://epiqe.cosapiinmobiliaria.com.pe/",
	//     index: 3,
	//     button:"Registrate aquí",
	// },
	// {
	//     project: "sente",
	//     imgBrand: "brand-sente",
	//     imgProject: require("../assets/images/render/render-sente.jpg"),
	//     imgBrand: require("../assets/images/multiproducto/sente.png"),
	//     allFases:[{fase:"",type:"Entrega Inmediata"},],
	//     fases: "Entrega inmediata",
	//     fase: "Entrega inmediata",
	//     frase:"A unos pasos de Jesús María",
	//     valuation: "",
	//     ...PROJECT_LOC.sente,
	//     ...PROJECTS_TIPOLOGIES.sente,
	//     dorms: "2 y 3",
	//     dormsShow: "2 y 3 Dorms",
	//     meters: "53m² - 76m²",
	//     views: {desde:"325",hasta:"474"},
	//     tendency: "Tendencia",
	//     all: "all",
	//     link: "https://sente.pe/",
	//     index: 5,
	//     button:"Registrate aquí",
	// },
	// {
	//     project: "prana",
	//     imgBrand: "brand-prana",
	//     imgProject: require("../assets/images/render/render-prana.jpg"),
	//     fases: "Vendido",
	//     fase: "Vendido",
	//     valuation: "",
	//     ...PROJECT_LOC.prana,
	//     dorms: "",
	//     dormsShow: "2 y 3 Dorms",
	//     views: "1",
	//     tendency: "Tendencia",
	//     all: "all",
	//     link: "",
	//     index: 6,
	//     button:"Conoce el proyecto",
	// },
	// {
	//     project: "duplo",
	//     imgBrand: "brand-duplo",
	//     imgProject: require("../assets/images/render/render-duplo.jpg"),
	//     fases: "Vendido",
	//     fase: "Vendido",
	//     valuation: "",
	//     ...PROJECT_LOC.duplo,
	//     dorms: "Tendencia",
	//     dormsShow: "2 y 3 Dorms",
	//     views: "1",
	//     tendency: "Tendencia",
	//     all: "all",
	//     link: "",
	//     index: 7,
	//     button:"Conoce el proyecto",
	// },
	// {
	//     project: "velia",
	//     imgBrand: "brand-velia",
	//     imgProject: require("../assets/images/render/render-velia.jpg"),
	//     fases: "Vendido",
	//     fase: "Vendido",
	//     valuation: "",
	//     ...PROJECT_LOC.velia,
	//     dorms: "",
	//     dormsShow: "1, 2 y 3 Dorms",
	//     views: "1",
	//     tendency: "Tendencia",
	//     all: "all",
	//     link: "",
	//     index:8,
	//     button:"Conoce el proyecto",
	// },
];

export const dataInicio = {
	sliderMain: SLIDER_HOME,
	cardsFilter: PROJECT_CARDS,
};
