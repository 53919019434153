import './whatsapp.scss';
// import { ModalForm } from '../modalLead/modalForm';
import { useEffect, useRef, useState } from 'react';
import wpp from '../../assets/images/icons/whatsapp.svg';

export const Whatsapp = () => {
	const [active, setActive] = useState(false);
	const [url, setUrl] = useState('https://api.whatsapp.com/send?phone=51966874541&text=Hola,%20visit%C3%A9%20su%20web%20y%20deseo%20m%C3%A1s%20informaci%C3%B3n,%20por%20favor');
	const refWpp = useRef();
	const hiddenWppScroll = e => {
		console.log(e);
		const footer = document.querySelector('.footer');
		if (window.scrollY + window.innerHeight >= footer.offsetTop) {
			setActive(true);
		}
	};
	useEffect(() => {
		try {
			document.addEventListener('DOMContentLoaded', event => {
				const footer = document.querySelector('footer');
				const fixedComponent = document.querySelector('.content-whatsapp');

				const observer = new IntersectionObserver(
					entries => {
						entries.forEach(entry => {
							if (entry.isIntersecting) {
								fixedComponent.classList.add('active');
							} else {
								fixedComponent.classList.remove('active');
							}
						});
					},
					{
						root: null,
						threshold: 0,
					}
				);

				observer.observe(footer);
			});

			// if (window.location.pathname.includes('proximos-proyectos')) {
			// 	setUrl('https://wa.me/51966874541?text=Hola,%20he%20visitado%20su%20web%20de%20nuevos%20proyectos,%20y%20deseo%20más%20información');
			// }
		} catch (error) {
			console.log(error);
		}
	}, []);
	return (
		<div className={`content-whatsapp ${active ? 'active' : ''}`} ref={refWpp}>
			<a href={url} target='_blank' className='content-loader'>
				<img src={wpp} alt='' />
			</a>
			{/* <ModalForm /> */}
		</div>
	);
};
