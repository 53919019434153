import $ from 'jquery';

export const moveTo = (elementFrom,elementTo, project) => {
    let navHeight = document.querySelector("#nav").clientHeight
    window.scrollTo(offset(elementFrom).top,offset(elementTo).top-(navHeight+30))
}

export const offset = (e)=>{
    console.log({offset:document.querySelector(e).getBoundingClientRect()})
    let element = document.querySelector(e)
    if(element){
        try { 
            let top = element.getBoundingClientRect().top + window.scrollY 
            let left = element.getBoundingClientRect().left
            return {top:top,left: left}
        }catch(err){
            console.log(err.message)
            return "doesnt exist"
        }
    }else{
        console.log("doesn't exist")
    }
}
export const settingsCards = {
    dots: false,
    arrows:false,
    infinite: false,
    centerMode: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
}
export const options = {
    items: 1,
    rewind: false,
    loop:false,
    nav:true,
    center:false,
    autoplay:false,
    smartSpeed:600,
    autoWidth:true,
    dots:true,
    margin:25,
    navText: [
        `<img src='${require("../../assets/images/icons/slider-signal-left.svg").default}'></img>`,
        `<img src='${require("../../assets/images/icons/slider-signal-right.svg").default}'></img>`,
    ],
}
function dragged (event) {
    try {
        
        if(event.page.index >= 0){
            $(`.brand`).removeClass('active')
            $(`.brand-${event.page.index}`).addClass("active").siblings().removeClass("active")
            let owl = $("#carousel-target").owlCarousel()
            owl.trigger('to.owl.carousel', [event.page.index, 300]);
        }
    } catch (error) {
        
    }
}
export const events = {
    onDragged: dragged,
    onChanged: dragged,
}

export const initAnimation = () => {
    let controller = new window.ScrollMagic.Controller()
    var tl = new window.TimelineMax();
    var tl2 = new window.TimelineMax();
    tl.staggerFrom(".hand-left", 1.8, { y: 0, x: -400, stagger: { amount: 0.75 }});
    tl2.staggerFrom(".hand-right", 1.8, { y: 0, x: 400, stagger: { amount: 0.75 }});
    var scene = new window.ScrollMagic.Scene({
        triggerElement: "#fase-0",
        duration: "100%",
        triggerHook: 0.5
    }).setTween(tl).addTo(controller);

    var scene = new window.ScrollMagic.Scene({
        triggerElement: "#fase-0",
        duration: "100%",
        triggerHook: 0.5
    }).setTween(tl2).addTo(controller);
}

export const onChangeInput =(setter,select)=>{
    setter(prevState => ({
        ...prevState,
        [select.target.name]:select.target.value
    }));
}