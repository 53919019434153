import axios from 'axios';
import { SPERANT_ENV } from '../../../data/projects';
import { ReferredMail } from '../Mails/Referred.html';
import { ReferrerMail } from '../Mails/Referrer.html';
import { MailService } from './MailService';
import { SperantService } from './SperantService';

export class LeadService {
	#sperantService = new SperantService();
	#mailService = new MailService();
	#lead = {};
	#client = {};
	#project = {};
	#referred = null;
	#utmData = { utm_source: 'Orgánico' };

	constructor() {}

	/**
	 * Datos del lead / referente
	 * @param {Object} data
	 */
	setLeadData(data) {
		this.#lead = data;
		return this;
	}

	/**
	 * Datos del referido
	 * @param {*} data
	 * @returns
	 */
	setReferredData(data) {
		this.#referred = data;
		return this;
	}

	/**
	 *
	 * @param {*} environment
	 */
	setProject(project) {
		this.#project = project;
		this.#sperantService.environment(project.environment);
		return this;
	}

	async save() {
		let data = this.#lead;
		let payload = {};
		if (this.#referred) {
			data = this.#referred;
			if (this.#project.environment === SPERANT_ENV.cosapi) {
				Object.assign(payload, {
					extra_fields: {
						nombres: this.#lead.fname,
						apellidos: this.#lead.lname,
						correo_electronico: this.#lead.email,
						dni: this.#lead.document,
						celular: this.#lead.telephone,
					},
				});
			}
			if (this.#project.environment === SPERANT_ENV.cosapilar) {
				Object.assign(payload, {
					extra_fields: {
						nombres_r: this.#lead.fname,
						apellidos_r: this.#lead.lname,
						correo_electronico_r: this.#lead.email,
						dni_r: this.#lead.document,
						celular_r: this.#lead.telephone,
					},
				});
			}
		}

		Object.assign(payload, {
			fname: data.fname,
			lname: data.lname,
			email: data.email,
			phone: data.telephone,
			project_id: this.#project.id,
			interest_type_id: this.#lead.interest_type_id || 4,
			document_type_id: 1,
			document: data.document,
			observation: [data.motive && `Motivo: ${data.motive}`, data.time_to_contact && `Horario de contacto: ${data.time_to_contact}`, data.working_area && `Area de trabajo: ${data.working_area}`]
				.filter(Boolean)
				.join(', '),
			input_channel_id: data.input_channel_id,
			source_id: data.source_id,
		});

		new URLSearchParams(localStorage.getItem('url_query')).forEach((v, k) => {
			/utm_/.test(k) && (this.#utmData[`${k.toLowerCase()}`] = v);
		});

		Object.assign(payload, this.#utmData);
		try {
			this.saveToSheets();
		} catch (error) {}

		const {
			data: { data: client },
		} = await this.#sperantService.createClient(payload);
		const {
			data: { data: user },
		} = await this.#sperantService.users(client.attributes.last_agent_assigned);
		this.#client = client;

		user.attributes.phone = user.attributes.phone.replace(/\s/g, '').replace(/\+-/g, '').replace(/\(/g, '').replace(/\)/g, '').replace(/\+51/g, '').split('/')[0];

		this.#client.last_agent_assigned = user.attributes;

		if (this.#referred) {
			await this.sendMailToOwner();
			await this.sendMailToReferred();
		}
		return this.#client;
	}

	sendMailToOwner() {
		return this.#mailService
			.from('Cosapi Inmobiliaria <no-reply@cosapiinmobiliaria.com.pe>')
			.to(this.#lead.email)
			.subject(`${this.#lead.fname}, gracias por ayudar a ${this.#referred.fname} a encontrar el depa de sus sueños`)
			.html(ReferrerMail({ owner: this.#lead, referred: this.#referred, project: this.#project.name }))
			.send();
	}

	sendMailToReferred() {
		return this.#mailService
			.from('Cosapi Inmobiliaria <no-reply@cosapiinmobiliaria.com.pe>')
			.to(this.#referred.email)
			.subject(`¡Hola ${this.#referred.fname}! ${this.#lead.fname} encontró el depa de tus sueños ¡Descúbrelo aquí!`)
			.html(ReferredMail({ owner: this.#lead, referred: this.#referred, project: this.#project.name }))
			.send();
	}

	/**
	 *
	 * @returns
	 */
	saveToSheets = _ => {
		const date = new Date().toLocaleString('es-PE', { timeZone: 'America/Lima' });
		let sheet = 'Cosapi Web';
		let row = [date, this.#lead.fname, this.#lead.lname, this.#lead.telephone, this.#lead.email, this.#lead.motive, this.#project.name, this.#lead.time_to_contact];

		if (this.#referred) {
			sheet = 'Referidos Cosapi Web';
			row = [
				date,
				this.#project.name,
				`${this.#lead.fname} ${this.#lead.lname}`,
				this.#lead.document,
				this.#lead.telephone,
				this.#lead.email,
				`${this.#referred.fname} ${this.#referred.lname}`,
				this.#referred.email,
				this.#referred.document,
				this.#referred.telephone,
			];
		}

		if (this.#project.comingSoon) {
			sheet = 'Próximos proyectos';
			row = row.filter(Boolean);
		}

		row = row.concat([this.#utmData.utm_source, this.#utmData.utm_medium, this.#utmData.utm_campaign, this.#utmData.utm_content, this.#utmData.utm_term]);

		axios.post('https://cors.formulaperu.com/https://script.google.com/macros/s/AKfycbws7GCpc1eEN5ScQ_IisUkLEwKQHvY_XCe5_KEbXA3ytUWVtA/exec', {
			ss_id: '1CfCxJdJJDWvfmixvX7AdowGGq_LfiBuP823m4Ryj3YA',
			range: `${sheet}!A:XX`,
			values: [row],
		});
	};
}
