import React, { Component, createRef, useState } from 'react';
import './navbar.scss';
import MediaQuery from 'react-responsive';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
class Navbar extends Component {
	constructor(props) {
		super(props);
		this.activePosition = this.activePosition.bind(this);
		this.close = this.close.bind(this);
		this.toggle = this.toggle.bind(this);
		this.moveTo = this.moveTo.bind(this);
		this.offset = this.offset.bind(this);
		this.state = {
			activeLine: 0,
			activeNav: true,
			initTransparent: true,
			closeMore: false,
			toggle: false,
			links: [
				{ index: 0, title: 'Proyectos', link: '/', show: false, initTransparent: true },
				{ index: 0, title: 'Proyectos', link: '/#proyectos', initTransparent: true },
				{ index: 1, title: 'Próximos proyectos', link: '/proximos-proyectos', show: true, initTransparent: false },
				{ index: 2, title: 'Conócenos', link: '/conocenos', initTransparent: false },
				{ index: 3, title: 'Referidos', link: '/referidos', initTransparent: false },
				{ index: 4, title: 'Convenios', show: false, link: '/convenios-y-beneficios' },
				{ index: 5, title: 'Atención al cliente', link: '/atencion-al-cliente' },
				{ index: 6, title: 'Tu depa paso a paso', link: '/inversion' },
				{ index: 7, title: 'Aliados', link: '/aliados', show: false },
				{ index: 5, title: 'Preguntas frecuentes', link: '/preguntas-frecuentes', show: false },
				{ index: 5, title: 'Preguntas frecuentes', link: '/atencion-al-propietario', show: false },
				{ index: 10, title: 'Multiproducto', link: '/multiproducto', show: false },
			],
		};
		this.nav = React.createRef();
	}
	componentDidUpdate(props, prevpros) {
		if (this.props.location.pathname != '/') {
			window.scrollTo(0, 0);
		}
	}
	componentDidMount() {
		// window.scrollTo(0,0)
		let location = this.state.links.filter((item, index) => {
			let data;

			if (window.location.href.includes(item.link)) {
				if (item.initTransparent) {
					this.setNavbarInactive();
				} else {
					this.setNavbarActive();
				}
				this.setState({ activeLine: item.index, initTransparent: item.initTransparent });
				data = item.index;
			}
			return data;
		});
		window.onscroll = () => {
			if (this.state.initTransparent == true) {
				window.scrollY == 0 ? this.setNavbarInactive() : this.setNavbarActive();
			}
		};
	}
	setNavbarActive() {
		document.querySelector('.navbar').classList.add('active');
	}
	setNavbarInactive() {
		document.querySelector('.navbar').classList.remove('active');
	}
	activePosition(e, position, options, item) {
		item.initTransparent ? this.setNavbarInactive() : this.setNavbarActive();
		this.setState({ activeLine: item.index });
		if (options.move == true) {
			this.moveTo(options.from, options.to);
		}
		if (item.initTransparent != true) {
			window.onscroll = () => {};
		}
		if (item.initTransparent == true) {
			window.onscroll = () => {
				window.scrollY == 0 ? this.setNavbarInactive() : this.setNavbarActive();
			};
		}
	}
	close(e) {
		this.setState({ closeMore: true });
	}
	toggle(e) {
		this.setState({ toggle: !this.state.toggle });
		// this.state.toggle == false ? document.querySelector("html").style="overflow-y: hidden;" : document.querySelector("html").style="overflow-y: scroll;"
	}
	moveTo = (elementFrom, elementTo) => {
		this.setState({ toggle: false });
		if (document.querySelector(elementTo)) {
			let navHeight = this.nav.current.clientHeight;
			window.scrollTo(this.offset(elementFrom).top, this.offset(elementTo).top - (navHeight + 40));
		}
	};
	offset(e) {
		let element;
		if (typeof e == 'object') {
			element = e;
		} else {
			element = document.querySelector(e);
		}
		if (element) {
			try {
				let top = element.getBoundingClientRect().top + window.scrollY;
				let left = element.getBoundingClientRect().left;
				return { top: top, left: left };
			} catch (err) {
				return 'dosnt exist';
			}
		} else {
			console.log("doesn't exist");
		}
	}
	render() {
		return (
			<nav ref={this.nav} id='nav' className={`navbar`}>
				<div className={`more b-primary ${this.state.closeMore ? 'close' : ''}`}>
					<div className='container'>
						<span className='sub-title sub-title-1 c-white'>El depa de tus sueños</span>
						<div className='barra'></div>
						<span className='sub-title c-white'>La facilidad de separar tu depa desde donde estés.</span>
						<a href='https://xn--eldepadetussueos-kub.pe/' className='b-white c-primary btn-know'>
							Saber más
						</a>
						<div
							className='button'
							onClick={e => {
								this.close();
							}}
						>
							<div className='add'></div>
						</div>
					</div>
				</div>

				<div className='container content-nav'>
					<Link
						to='/'
						className='brand'
						onClick={() => {
							window.scrollTo(0, 0);
						}}
					>
						<img className='logo-white' src={require('../../assets/images/icons/brand-cosapi.png')}></img>
						<img className='logo-sky' src={require('../../assets/images/icons/brand-cosapi-blue.png')}></img>
					</Link>
					<MediaQuery query='(min-width: 1200px)'>
						<OptionNav options={this.state} position={this.activePosition} moveTo={this.moveTo}></OptionNav>
					</MediaQuery>
					<MediaQuery query='(max-width: 1200px)'>
						<div
							className={`toggle ${this.state.toggle === true ? 'active' : ''}`}
							onClick={e => {
								this.toggle(e);
							}}
						>
							<div className='line line-1'></div>
							<div className='line line-2'></div>
						</div>
					</MediaQuery>
				</div>
				<MediaQuery query='(max-width: 1200px)'>
					<OptionNav options={this.state} position={this.activePosition} moveTo={this.moveTo}></OptionNav>
				</MediaQuery>
			</nav>
		);
	}
}
export default withRouter(Navbar);
class OptionNav extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		return (
			<div className={`container-link ${this.props.options.toggle ? 'active' : ''}`}>
				<div className='links'>
					<div className={`link title-menu`}>
						<span className='text-link'>Menú</span>
					</div>
					{this.props.options.links.map((item, index) => {
						if (item.show != false) {
							return (
								<Link
									to={item.link}
									id={item.id}
									className={`link ${this.props.options.activeLine == item.index ? 'active' : ''} `}
									onClick={e => {
										this.props.position(e, item.index, { move: true, from: e, to: '#projects', initTransparent: item.initTransparent }, item);
									}}
									key={index + 'link'}
								>
									<span className={`text-link`}>
										{item.title}
										{item.index === 1 ? <div className='point'></div> : null}
									</span>
									<span className='barra-line'></span>
								</Link>
							);
						}
					})}
					<div className='barra'></div>
					<div className='link-buttons'>
						<a target='_blank' href='https://xn--eldepadetussueos-kub.pe/' className={`link button depa`}>
							<img className='img-depa' src={require('../../assets/images/icons/web.png')}></img>
							<div className='content-text cart'>
								<span className='text-link cart'>El depa de tus sueños</span>
								<span className='sub-text-link cart'>Cotiza y separa online</span>
							</div>
						</a>
						<Link
							to='/contactanos'
							className={`link button ${this.props.options.activeLine == 6 ? 'active' : ''}`}
							onClick={e => {
								this.props.position(e, 6, { move: true, from: e, to: '#projects', initTransparent: false }, this.props.options.links[9]);
							}}
						>
							<div className='content-text cart'>
								<span className='text-link cart'>Contáctanos</span>
								<span className='barra-line'></span>
							</div>
						</Link>
					</div>
					<div className='link-buttons redes'>
						<a target='_blank' href='https://www.facebook.com/cosapiinmobiliariaoficial/' className='item'>
							<div className='facebook'></div>
						</a>
						<a target='_blank' href='https://www.instagram.com/cosapi.inmobiliaria/' className='item'>
							<div className='instagram'></div>
						</a>
						<a target='_blank' href='https://www.youtube.com/channel/UCfr9B6LMPgZDI6LVSsIA-yQ' className='item'>
							<div className='youtube'></div>
						</a>
						<a target='_blank' href='https://pe.linkedin.com/company/cosapiinmobiliaria' className='item'>
							<div className='linkdin'></div>
						</a>
					</div>
				</div>
			</div>
		);
	}
}
