import React, { Component } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import '../BuscandoDepa/BuscandoDepa.scss';
import { PROJECTS_CRM, PROJECTS_DATA } from '../../data/projects';
import { SperantService } from '../../component/common/Http/SperantService';
import { FormContainer, setInputProps } from '../../component/common/forms/Form';
import { BaseProjectSubscribeValidatonSchema } from '../../component/common/forms/constraints/ValidatonSchemas';
import Swal from 'sweetalert2';
import { OwnerEmail } from '../../component/common/Mails/owner.html';
import { MailService } from '../../component/common/Http/MailService';

export default class AtencionPropietario extends Component {
	constructor(props) {
		super(props);
		this.submitForm = this.submitForm.bind(this);
		this.sperantService = new SperantService();
		this.mailService = new MailService();

		this.state = {
			expanded: '',
			icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 38"><defs><style>.cls-1{fill:#eee;}.cls-2{fill:none;stroke:#707070;stroke-linecap:round;stroke-width:2px;}</style></defs><title>Recurso 1laptop</title><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><path class="cls-1" d="M19,0q.93,0,1.86.09c.62.06,1.24.15,1.85.27s1.21.28,1.81.46a18.25,18.25,0,0,1,1.75.63A17.31,17.31,0,0,1,28,2.24c.55.3,1.08.62,1.6,1s1,.72,1.49,1.11.94.82,1.38,1.25A18.37,18.37,0,0,1,33.69,7c.39.48.76,1,1.11,1.49s.66,1.05,1,1.6a17.31,17.31,0,0,1,.79,1.69,18.25,18.25,0,0,1,.63,1.75c.18.6.33,1.2.45,1.81a16.52,16.52,0,0,1,.28,1.85Q38,18.07,38,19t-.09,1.86a16.52,16.52,0,0,1-.28,1.85c-.12.61-.27,1.21-.45,1.81a18.25,18.25,0,0,1-.63,1.75A17.31,17.31,0,0,1,35.76,28c-.3.55-.62,1.08-1,1.6s-.72,1-1.11,1.49-.81.94-1.26,1.39-.9.85-1.38,1.25-1,.76-1.49,1.11-1,.66-1.6,1a17.31,17.31,0,0,1-1.69.79,18.25,18.25,0,0,1-1.75.63c-.6.18-1.2.33-1.81.45a16.52,16.52,0,0,1-1.85.28Q19.93,38,19,38t-1.86-.09a16.52,16.52,0,0,1-1.85-.28c-.61-.12-1.21-.27-1.81-.45a18.25,18.25,0,0,1-1.75-.63A17.31,17.31,0,0,1,10,35.76c-.55-.3-1.08-.62-1.6-1s-1-.72-1.49-1.11-1-.81-1.39-1.25-.85-.91-1.25-1.39-.76-1-1.11-1.49-.66-1-1-1.6a17.31,17.31,0,0,1-.79-1.69,18.25,18.25,0,0,1-.63-1.75c-.18-.6-.33-1.2-.45-1.81a16.52,16.52,0,0,1-.28-1.85Q0,19.93,0,19t.09-1.86a16.52,16.52,0,0,1,.28-1.85c.12-.61.27-1.21.45-1.81a18.25,18.25,0,0,1,.63-1.75A17.31,17.31,0,0,1,2.24,10c.3-.55.62-1.08,1-1.6s.72-1,1.11-1.49.81-1,1.25-1.39S6.47,4.71,7,4.31s1-.76,1.49-1.11,1.05-.66,1.6-1a17.31,17.31,0,0,1,1.69-.79A18.25,18.25,0,0,1,13.48.82c.6-.18,1.2-.33,1.81-.46S16.52.15,17.14.09,18.38,0,19,0Z"/><line class="cls-2 line-transform" x1="19.5" y1="12.5" x2="19.5" y2="25.77" style="&#10;"/><line class="cls-2" x1="25.77" y1="19.5" x2="12.5" y2="19.5"/></g></g></svg>`,
		};
	}
	handleChange = panel => (event, newExpanded) => {
		this.setState({ expanded: newExpanded ? panel : false });
	};
	submitForm(values, { setSubmitting, resetForm }) {
		const project = PROJECTS_CRM[values.project];

		this.mailService.from('Cosapi Inmobiliaria <no-reply@formulaperu.com>').to(values.email).subject('Manuales').html(OwnerEmail(values));

		this.mailService
			.send()
			.then(r => {
				window.fbq && window.fbq('track', 'Lead', { project: values.project });
				Swal.fire({
					title: '',
					text: 'Muchas Gracias, hemos enviado los manuales a su correo',
					icon: 'success',
					confirmButtonText: 'Aceptar',
					confirmButtonColor: 'var(--primary)',
				});
			})
			.catch(e => {
				Swal.fire({
					title: '',
					text: '¡Error! Intentelo más tarde',
					icon: 'error',
					confirmButtonText: 'Aceptar',
					confirmButtonColor: 'red',
				});
			})
			.finally(_ => setSubmitting(false));
	}
	initialValues = {
		fname: '',
		lname: '',
		email: '',
		telephone: '',
		project: '',
		terms: true,
	};
	render() {
		return (
			<main className='page-atencion'>
				<div className='background'>
					<div className='mini-ball-white mini-ball-white-1'></div>
					<div className='mini-ball-white mini-ball-white-2'></div>
					<div className='contain '>
						<div className='banner'>
							<div className='content-info'>
								<img className='img-banner' src={require('../../assets/images/atencion/contigo.png')}></img>
								<span className='title gotham-bold c-white'>¡Estamos para atenderte, estamos contigo!</span>
								<p className='paragraph c-white'>
									En <span className='gotham-bold'>Cosapi Inmobiliaria</span> estamos felices de atender a nuestros propietarios en cualquier duda, solicitud y requerimiento que
									necesite. Te pedimos ingresar a nuestra plataforma de post venta con tu usuario y contraseña para ayudarte con todas tus consultas ¡Nos vemos!
								</p>
								<p className='paragraph c-white'>Ingresa a nuestra plataforma de post venta con tu usuario y contraseña para ayudarte con todas tus consultas ¡Nos vemos!</p>
								<a href='https://cosapi.sperant.com/extranet/sign_in' target='__blank' className='btn b-white c-primary miryad-pro-regular text-center'>
									Ingresar
								</a>
							</div>
							<img className='background-model' src={require('../../assets/images/atencion/asesor.png')}></img>
						</div>
					</div>
				</div>
				<div className='bg-white'>
					<div className='contain expanded'>
						<div className='mini-ball mini-ball-4'></div>
						<div className='mini-ball mini-ball-3'></div>
						<div className='content-questions'>
							<div className='questions'>
								<div className='content-title'>
									<div className='title c-primary gotham-bold'>Preguntas frecuentes</div>
									<div className='c-primary paragraph'>
										<span className='c-primary gotham-bold'>¿Tienes alguna duda?</span> Busca en nuestra lista de preguntas frecuentes y encontremos juntos una solución
									</div>
								</div>
								<div className='content-acordeon'>
									<div className='column'>
										<Accordion expanded={this.state.expanded === 'panel1'} square onChange={this.handleChange('panel1', this)}>
											<AccordionSummary aria-controls='panel2a-content' id='panel2a-header'>
												<Typography>
													<div className='icon' dangerouslySetInnerHTML={{ __html: this.state.icon }}></div>
													<p className=' c-secondary gotham-bold'>¿Cómo desembolsar?</p>
												</Typography>
											</AccordionSummary>
											<AccordionDetails>
												<Typography>
													<p className='paragraph c-secondary'>
														1. Una vez firmada la minuta de compra venta y realizado el pago de inicial solicitado por el banco debe hacer lo siguiente:<br></br>- Llenas
														las solicitudes del banco para el crédito hipotecario. Entregar toda documentación actualizada de los compradores a su asesor inmobiliario y
														bancario.<br></br>- El banco realizará con ello el estudio de títulos, donde se tendrá que pagar el monto correspondiente a la tasación del
														inmueble*<br></br>- El banco armará el contrato hipotecario con el cronograma y lo enviará a la notaria.<br></br>- Una vez se encuentren los
														documentos en notaria, ellos coordinarán con usted para gestionar las firmas correspondientes y los pagos notariales y registrales. Dando por
														concluido el proceso de desembolso con el banco promotor. * En caso fuese con un banco distinto al promotor, la diferencia radica en que la
														inmobiliaria tendrá que gestionar la carta fianza ante la entidad promotora, siendo responsabilidad del pago el cliente.
													</p>
												</Typography>
											</AccordionDetails>
										</Accordion>
										<Accordion expanded={this.state.expanded === 'panel2'} square onChange={this.handleChange('panel2', this)}>
											<AccordionSummary aria-controls='panel2a-content' id='panel2a-header'>
												<Typography>
													<div className='icon' dangerouslySetInnerHTML={{ __html: this.state.icon }}></div>
													<p className='c-secondary gotham-bold'>¿Puedo recibir mayor información sobre los tiempos de mantenimiento?</p>
												</Typography>
											</AccordionSummary>
											<AccordionDetails>
												<Typography>
													<p className='paragraph c-secondary'>
														Buscamos estar siempre comunicados con nuestros clientes, en ese sentido una vez tengamos el requerimiento nos acercamos para entender la
														casuística, en muchos casos podremos resolverlo al momento, sin embargo en caso sea un tratamiento de mayor complejidad le estaremos informando
														el proceso de solución.
													</p>
												</Typography>
											</AccordionDetails>
										</Accordion>
										<Accordion expanded={this.state.expanded === 'panel3'} square onChange={this.handleChange('panel3', this)}>
											<AccordionSummary aria-controls='panel2a-content' id='panel2a-header'>
												<Typography>
													<div className='icon' dangerouslySetInnerHTML={{ __html: this.state.icon }}></div>
													<p className='c-secondary gotham-bold    '>¿Cómo ingreso al intranet y para qué sirve?</p>
												</Typography>
											</AccordionSummary>
											<AccordionDetails>
												<Typography>
													<p className='paragraph c-secondary'>
														El intranet nos permite estar más cerca de ti, una vez firmada la minuta compra venta, se te dará acceso para contar con la siguiente
														información:<br></br>
														1. Firmada la compra venta:<br></br>
														Podrás ingresar y ver documentos y tutoriales para el proceso de compra, adquirir un inmueble puede llevar a bastantes dudas, lo que buscamos es
														estar siempre cerca de ti!<br></br>
														2. Entregado el departamento:<br></br>
														Podrás gestionar tus procesos de atención al cliente / post venta, esto permitirá llevar un orden y poder atenderte de la manera adecuada. Les
														pedimos siempre entrar al intranet para el registro respectivo.<br></br>
													</p>
												</Typography>
											</AccordionDetails>
										</Accordion>
									</div>
								</div>
							</div>
						</div>
						<section className='formulario'>
							<FormContainer initialValues={this.initialValues} validationSchema={BaseProjectSubscribeValidatonSchema} onSubmit={this.submitForm}>
								{form => {
									const { handleSubmit, isSubmitting } = form;
									return (
										<form className='form' onSubmit={handleSubmit}>
											<span className='c-secondary paragraph'>
												Si eres propietario, déjanos tus datos y elige el proyecto donde resides para enviarte{' '}
												<span className='gotham-bold'>los manuales de convivencia, uso y mantenimiento</span>
											</span>
											<div className='content-inputs'>
												<input placeholder='Nombre' {...setInputProps('fname', 'input', form)}></input>
												<input placeholder='Apellidos' {...setInputProps('lname', 'input', form)}></input>
												<input placeholder='Teléfono de contacto' {...setInputProps('telephone', 'input-line', form)}></input>
												<input placeholder='Correo electrónico' {...setInputProps('email', 'input-line', form)}></input>
												<select name='project' {...setInputProps('project', 'input-line', form)}>
													<option value=''>Selecciona tu proyecto</option>
													{Object.keys(PROJECTS_DATA).map(
														(key, i) =>
															!PROJECTS_DATA[key].allowLeadCreation && (
																<option key={i} value={key}>
																	{PROJECTS_DATA[key].name}
																</option>
															)
													)}
												</select>
											</div>
											<button type='submit' className='btn-submit btn-radio c-white b-primary' disabled={isSubmitting}>
												{isSubmitting ? 'Enviando' : 'Enviar'}
											</button>
										</form>
									);
								}}
							</FormContainer>
						</section>
					</div>
				</div>
			</main>
		);
	}
}
