import { useState } from 'react';
import { ReferalSchema } from '../../component/common/forms/constraints/ValidatonSchemas';
import { checkableBoolProps, FormContainer, setInputProps } from '../../component/common/forms/Form';
import MediaQuery from 'react-responsive';
import OwlCarousel from 'react-owl-carousel2';
import './referidos.scss';
import { PROJECTS_DATA } from '../../data/projects';
import { SperantService } from '../../component/common/Http/SperantService';
import Swal from 'sweetalert2';
import $ from 'jquery';
import { LeadService } from '../../component/common/Http/LeadService';
export default function Referidos() {
	const options = {
		items: 1,
		rewind: true,
		loop: false,
		nav: false,
		center: false,
		autoplay: true,
		smartSpeed: 600,
		autoWidth: true,
		dots: true,
		margin: 25,
	};
	const projects = [
		{ name: 'Midgo', status: 'Preventa', img: require('../../assets/images/referidos/midgo.png') },
		{ name: 'Momen', status: 'Preventa', img: require('../../assets/images/referidos/momen-price.png') },
		{ name: 'Ativa', status: 'En Construcción', img: require('../../assets/images/referidos/Ativa.png') },
		{ name: 'Muvin', status: 'Entrega Inmediata', img: require('../../assets/images/referidos/Muvin.png') },
		{ name: 'Nesta', status: 'Entrega inmediata', img: require('../../assets/images/referidos/nesta.png') },
		// {name:'Sente',status:"Entrega inmediata",img:require('../../assets/images/referidos/sente.png')},
	];
	const offset = e => {
		let element = document.querySelector(e);
		if (element) {
			try {
				let top = element.getBoundingClientRect().top + window.scrollY;
				let left = element.getBoundingClientRect().left;
				return { top: top, left: left };
			} catch (err) {
				return 'dosnt exist';
			}
		} else {
		}
	};
	const moveToScroll = project => {
		let select = document.getElementsByName('project')[0];
		select.value = project.toLowerCase();
		select.dispatchEvent(new Event('change', { bubbles: true }));
		let navHeight = document.querySelector('#nav').clientHeight;
		$('html, body').animate(
			{
				scrollTop: $('.information').offset().top - navHeight + 1,
			},
			700
		);
	};
	const events = {
		onDragged: function (event) {},
		onChanged: function (event) {},
	};
	return (
		<div className='referidos inicio'>
			<div className='container banner'>
				<div className='content-img'>
					<img className='' src={require('../../assets/images/referidos/img-family-friendly.png')}></img>
				</div>
				<MediaQuery minWidth={500}>
					<div className='content-projects d-flex'>
						{projects.map((project, index) => {
							return (
								<div
									className='project'
									onClick={() => {
										moveToScroll(project.name);
									}}
								>
									<img className='' src={project.img}></img>
									<p className={`paragraph c-${project.name}`}>{project.status}</p>
								</div>
							);
						})}
					</div>
				</MediaQuery>
				<MediaQuery maxWidth={499}>
					<div className='content-projects d-flex'>
						<OwlCarousel options={options} events={events} className='owl-carousel'>
							{projects.map((project, index) => {
								return (
									<div
										className='project'
										onClick={() => {
											moveToScroll(project.name);
										}}
									>
										<img className='' src={project.img}></img>
										<p className={`paragraph c-${project.name}`}>{project.status}</p>
									</div>
								);
							})}
						</OwlCarousel>
					</div>
				</MediaQuery>
			</div>
			<div className='section container formulario'>
				<div className='information'>
					<FormReferencial />
				</div>
			</div>
		</div>
	);
}

const FormReferencial = ({ handleSubmit }) => {
	const [activeModal, setActiveModal] = useState(false);
	const [sellerPhone, setsellerPhone] = useState(false);
	const [initialValues, setInitialValues] = useState({
		owner_fname: null,
		owner_lname: null,
		owner_email: null,
		owner_document: null,
		owner_telephone: null,
		terms: false,
		referred_fname: null,
		referred_lname: null,
		referred_email: null,
		referred_document: null,
		referred_telephone: null,
		project: null,
	});

	const submitForm = (values, { setSubmitting, resetForm }) => {
		const leadService = new LeadService();
		const project = PROJECTS_DATA[values.project];
		leadService.setProject(project);

		const owner = {
			fname: values.owner_fname,
			lname: values.owner_lname,
			email: values.owner_email,
			telephone: values.owner_telephone,
			document: values.owner_document,
		};

		const referred = {
			fname: values.referred_fname,
			lname: values.referred_lname,
			email: values.referred_email,
			telephone: values.referred_telephone,
			document: values.referred_document,
		};

		Object.assign(referred, {
			input_channel_id: project.input_channels.web,
			source_id: project.source_ids.referidos,
		});

		leadService
			.setLeadData(owner)
			.setReferredData(referred)
			.save()
			.then(() => {
				window.fbq && window.fbq('track', 'Lead', { project: values.project });

				document.querySelector('form').reset();
				setSubmitting(false);
				resetForm();

				setActiveModal(true);
			})
			.catch(e => {
				console.error(e);
				Swal.fire({
					title: '',
					text: '¡Error! Intentelo otra vez',
					icon: 'error',
					confirmButtonText: 'Aceptar',
					confirmButtonColor: 'red',
				});
			})
			.finally(_ => setSubmitting(false));
	};
	const closeModal = e => {
		console.log(e.target);
		if (e.target.className.includes('type-popup')) setActiveModal(false);
	};
	return (
		<FormContainer initialValues={initialValues} validationSchema={ReferalSchema} onSubmit={submitForm}>
			{form => {
				const { handleSubmit, isSubmitting } = form;
				return (
					<form className='form' onSubmit={handleSubmit}>
						<div
							className={`type-popup popup ${activeModal ? 'active' : ''}`}
							onClick={e => {
								closeModal(e);
							}}
						>
							<div className='content-popup'>
								<div
									className='btn-close type-popup'
									onClick={e => {
										closeModal(e);
									}}
								>
									<img src={require('../../assets/images/icons/btn-close.svg').default}></img>
								</div>
								<img className='background desktop' src={require('../../assets/images/icons/background-modal.png')}></img>
								<img className='background movil' src={require('../../assets/images/icons/background-modal-movil.png')}></img>
								<img className='icon-check' src={require('../../assets/images/icons/check_icon.svg').default}></img>
								<span className='title'>¡Gracias!</span>
								<span className='sub-title '>Dentro de poco un asesor se pondrá en contacto contigo.</span>
								{sellerPhone && (
									<>
										<span className='sub-title bold'>También puedes contactarlo aquí</span>
										<div className='contact'>
											<a href={`https://wa.me/51${this.n.sellerPhone}`} target='_blank'>
												<img className='icon icon-whatsapp' src={require('../../assets/images/icons/whatsapp-white.svg').default}></img>
											</a>
											<a href={`tel:${this.state.sellerPhone}`} target='_blank'>
												<img className='icon' src={require('../../assets/images/icons/call-white.svg').default}></img>
											</a>
										</div>
									</>
								)}
							</div>
						</div>
						<div className='personal'>
							<h2 className='title'>
								¡Refiérenos a tu familia y amigos
								<br />
								<strong>y gana una Giftcard!</strong>
							</h2>
							<h3 className='sub-title'>Datos del Propietario</h3>
							<div className='content-inputs'>
								<input placeholder='Nombre(*)' {...setInputProps('owner_fname', '', form)}></input>
								<input placeholder='Apellidos(*)' {...setInputProps('owner_lname', '', form)}></input>
								<input placeholder='Correo Electrónico (*)' {...setInputProps('owner_email', '', form)}></input>
								<input placeholder='DNI (*)' {...setInputProps('owner_document', '', form)}></input>
								<input placeholder='Celular (*)' {...setInputProps('owner_telephone', '', form)}></input>
							</div>
							<MediaQuery minWidth={800}>
								<div className='politicas'>
									<input id='terms' type='checkbox' {...checkableBoolProps('terms', '', form)}></input>
									<label htmlFor='terms' className='label-accept'>
										<div className='check'></div>
									</label>
									<span className='terms'>
										Acepto las{' '}
										<a target='_blank' href={require('../../assets/files/Politica-de-privacidad.pdf')}>
											políticas de privacidad
										</a>
									</span>
								</div>
								<button type='submit' className='btn-submit btn-radio c-white b-primary' disabled={isSubmitting}>
									{isSubmitting ? 'Enviando...' : 'Enviar'}
								</button>
							</MediaQuery>
						</div>
						<div className='referencial'>
							<div className='content-form-referencial'>
								<h3 className='sub-title'>Datos del Referido</h3>
								<div className='content-inputs'>
									<input placeholder='Nombre (*)' {...setInputProps('referred_fname', '', form)}></input>
									<input placeholder='Apellidos (*)' {...setInputProps('referred_lname', '', form)}></input>
									<input placeholder='Correo Electrónico (*)' {...setInputProps('referred_email', '', form)}></input>
									<input placeholder='DNI (*)' {...setInputProps('referred_document', '', form)}></input>
									<input placeholder='Celular (*)' {...setInputProps('referred_telephone', '', form)}></input>
									<select {...setInputProps('project', '', form)} defaultValue={initialValues.project}>
										<option value=''>Selecciona el proyecto</option>
										{Object.keys(PROJECTS_DATA).map(
											(key, i) =>
												PROJECTS_DATA[key].allowLeadCreation && (
													<option key={i} value={key}>
														{PROJECTS_DATA[key].name}
													</option>
												)
										)}
									</select>
								</div>
							</div>
							<MediaQuery maxWidth={799}>
								<div className='politicas'>
									<input id='terms' type='checkbox' {...checkableBoolProps('terms', '', form)}></input>
									<label htmlFor='terms' className='label-accept'>
										<div className='check'></div>
									</label>
									<span className='terms'>
										Acepto las{' '}
										<a target='_blank' href={require('../../assets/files/Politica-de-privacidad.pdf')}>
											políticas de privacidad
										</a>
									</span>
								</div>
								<button type='submit' className='btn-submit btn-radio c-white b-primary' disabled={isSubmitting}>
									{isSubmitting ? 'Enviando...' : 'Enviar'}
								</button>
							</MediaQuery>
							<span className='nota-legal'>
								(*) Referidos de clientes que hayan adquirido un departamento en los proyectos de desarrollados por Cosapi Inmobiliaria y Gerpal ó Cosapi Inmobiliaria y Grupo Lar. Se
								acordará la entrega del gifcard después de la firma de minuta del departamento.
							</span>
						</div>
					</form>
				);
			}}
		</FormContainer>
	);
};
