import * as Yup from 'yup';

export const BaseInterestedConstraints = {
    fname: Yup.string().matches(/^[A-Za-z]+$/, 'El nombre no debe contener números')
        .required(),
    lname: Yup.string().matches(/^[A-Za-z]+$/, 'El nombre no debe contener números')
        .required(),
    email: Yup.string()
        .email()
        .required(),
    telephone: Yup.string()
        .matches(/[\+?\-?0-9]{7,}/)
        .required(),
}

export const ReferalSchema = (values) => Yup.object().shape({
    owner_fname: Yup.string().required(),
    owner_lname: Yup.string().required(),
    owner_email: Yup.string().email().required(),
    owner_document: Yup.string().required().max(8),
    owner_telephone: Yup.string().required().max(9),
    referred_fname: Yup.string().required(),
    referred_lname: Yup.string().required(),
    referred_email: Yup.string().email().required(),
    referred_document: Yup.string().required().max(8),
    referred_telephone: Yup.string().required().max(9),
    terms: Yup.boolean().oneOf([true], 'Debe aceptar los términos y condiciones'),
    project: Yup.string().required(),
});

export const MultiplyInterestedSchema = (values) => Yup.object().shape({
    project: Yup.string().required(),
    fase: Yup.string().required(),
    dorms: Yup.string().required(),
    flat_type: Yup.string().required(),
    fname: Yup.string().matches(/^[A-Za-z]+$/, 'El nombre no debe contener números').required(),
    lname: Yup.string().matches(/^[A-Za-z]+$/, 'El nombre no debe contener números').required(),
    telephone: Yup.string().required().max(9),
    email: Yup.string().email().required(),
    motive: Yup.string().required(),
    terms: Yup.boolean().oneOf([true], 'Debe aceptar los términos y condiciones'),
});

export const BaseProjectSubscribeValidatonSchema = (values) => Yup.object()
    .shape({
        ...BaseInterestedConstraints,
        project: Yup.string()
            .required(),
        terms: Yup.bool()
            .oneOf([true]).required()
    })

export const WorkerBenefitsSubscribeValidatonSchema = (values) => Yup.object()
    .shape({
        ...BaseInterestedConstraints,
        project: Yup.string()
            .required(),
        working_area: Yup.string()
            .required(),
        terms: Yup.bool()
            .oneOf([true]).required()
    })

export const HomeContactValidatonSchema = (values) => Yup.object()
    .shape({
        ...BaseInterestedConstraints,
        motive: Yup.string()
            .required(),
        project: Yup.string()
            .required(),
        time_to_contact: Yup.string().required(),
        terms: Yup.bool()
            .oneOf([true]).required()
    })

export const CommingSoonValidatonSchema = (values) => Yup.object()
    .shape({
        ...BaseInterestedConstraints,
        motive: Yup.string()
            .required(),
        project: Yup.string()
            .required(),
        terms: Yup.bool()
            .oneOf([true]).required()
    })

export const FAQContactValidatonSchema = (values) => Yup.object()
    .shape({
        ...BaseInterestedConstraints,
        message: Yup.string()
            .required(),
        terms: Yup.bool()
            .oneOf([true]).required()
    })

export const BaseValidatonSchema = (values) => Yup.object()
    .shape({
        ...BaseInterestedConstraints,
        terms: Yup.bool()
            .oneOf([true]).required()
    })